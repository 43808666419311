<template>
<div>
  <p></p><h5 align="center" style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;text-align:center"><p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all; text-align: center;"><span style="font-size: 14pt; line-height: 115%;"><span style="font-size: 12pt;">개인정보처리방침</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">총칙</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">(</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">주<span lang="EN-US">)</span>테이슨<span lang="EN-US">(</span>이하<span lang="EN-US"> ‘</span>회사<span lang="EN-US">’)</span>는 이용자의 개인정보를 중요시하며<span lang="EN-US">, “</span>정보통신망 이용촉진 및 정보보호<span lang="EN-US">”</span>에 관한 법률을 준수하고 있습니다<span lang="EN-US">. ‘</span>회사<span lang="EN-US">’</span>는 개인정보처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며<span lang="EN-US">, </span>개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다<span lang="EN-US">. </span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">또한 <span lang="EN-US">‘</span>회사<span lang="EN-US">’</span>는 개인정정보처리방침을 개정하는 경우 사이트 공지 및 이메일을 통하여 사전에 고지할 것입니다<span lang="EN-US">.&nbsp; </span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin"><span style="font-size: 12pt;">1.</span><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;"><span style="font-size: 12pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 12pt; line-height: normal; font-family: &quot;Times New Roman&quot;;"></span></span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin"><span style="font-size: 12pt;">개인정보의 수집 항목</span></span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><b><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">&nbsp;</span></b></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l6 level1 lfo2;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 회원가입<span lang="EN-US">, </span>이용자상담<span lang="EN-US">, </span>서비스
이용 등을 위해 아래와 같은 개인정보를 수집하고 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:10.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
1.0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:11.3pt;
mso-char-indent-count:1.13;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">계정<span lang="EN-US">ID(</span>이메일 주소<span lang="EN-US">), </span>비밀번호<span lang="EN-US">, </span>성<span lang="EN-US">, </span>이름<span lang="EN-US">, </span>생년월일<span lang="EN-US">, </span>소속<span lang="EN-US">(</span>회사<span lang="EN-US">)</span>명<span lang="EN-US">, </span>국적<span lang="EN-US">, </span>전화번호<span lang="EN-US">, </span>휴대폰번호</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:10.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
1.0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l6 level1 lfo2;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">서비스 이용 과정이나 사업처리 과정에서 아래와 같은 정보들이 생성되어
수집될 수 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">사용자
상태정보<span lang="EN-US">, </span>서비스 연동을 위한 타 서비스 아이디<span lang="EN-US">, </span>서비스
이용 기록<span lang="EN-US">, </span>접속 로그<span lang="EN-US">, </span>쿠키<span lang="EN-US">, </span>접속<span lang="EN-US"> IP </span>정보<span lang="EN-US">, </span>이용과정에서
디바이스정보<span lang="EN-US">(OS, </span>화면사이즈<span lang="EN-US">, </span>기종<span lang="EN-US">), </span>결제 기록<span lang="EN-US">, </span>이용 정지 기록</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:10.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
1.0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l6 level1 lfo2;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">유료 서비스 이용 과정에서 아래와 같은 결제 정보가 수집될 수 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:10.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
1.0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:10.0pt;
mso-char-indent-count:1.0;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">1) </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">신용카드로 결제시 <span lang="EN-US">: </span>카드사명</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
2.0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">2) </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin"><span style="font-weight: normal;">세금계산서 발행시 <span lang="EN-US">: </span>기관<span lang="EN-US">/</span>법인명<span lang="EN-US">, </span>대표자명<span lang="EN-US">, </span>사업자등록번호<span lang="EN-US">, </span>사업장주소<span lang="EN-US">, </span>업태<span lang="EN-US">, </span>업종<span lang="EN-US">, </span>담당자 이름<span lang="EN-US">, </span>담당자 전화번호<span lang="EN-US">, </span>세금계산서
수신 메일<span lang="EN-US">, </span>사업자 등록증 사본 등</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">2.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보의 수집 방법</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l10 level1 lfo3;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>의 홈페이지<span lang="EN-US">, </span>서면양식<span lang="EN-US">, </span>전화<span lang="EN-US">/</span>팩스<span lang="EN-US">, </span>전자우편<span lang="EN-US">, </span>이벤트
응모 등에 의한 개인정보 수집동의 절차를 통한 수집</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l10 level1 lfo3;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">개인정보 제공 및 활용 동의절차를 통한 제휴사로부터의 수집</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l10 level1 lfo3;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin"><span style="font-weight: normal;">상담내역 및 이력<span lang="EN-US">, </span>결제기록<span lang="EN-US">, </span>이용기록 등 서비스 제공과정에서 자동 수집<span lang="EN-US">, </span>추가로 생성되는 정보의
수집</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">3.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보의 이용 목적</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l7 level1 lfo4;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="line-height: 115%; letter-spacing: -0.45pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">회원 식별<span lang="EN-US">/</span>가입의사 확인<span lang="EN-US">, </span>본인<span lang="EN-US">/</span>연령 확인<span lang="EN-US">, </span>부정이용 방지</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l7 level1 lfo4;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">서비스 제공<span lang="EN-US">, </span>서비스 계약
이행<span lang="EN-US">, </span>구매 및 요금 결제<span lang="EN-US">, </span>청구서 발송<span lang="EN-US">, </span>요금 추심</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l7 level1 lfo4;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">서비스 오류 및 기능 개선</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l7 level1 lfo4;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">④<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="line-height: 115%; letter-spacing: -0.45pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">신규 서비스 개발<span lang="EN-US">, </span>다양한 서비스 제공<span lang="EN-US">, </span>문의사항
또는 불만처리<span lang="EN-US">, </span>공지사항 전달</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l7 level1 lfo4;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">⑤<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">이용자의 이용현황<span lang="EN-US">, </span>분석을
통한 컨설팅</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l7 level1 lfo4;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">⑥<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="line-height: 115%; letter-spacing: -0.45pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">서비스 이용 기록<span lang="EN-US">, </span>접속 빈도 및 서비스 이용에 대한 통계<span lang="EN-US">, </span>프라이버시 보호 측면의 서비스 환경 구축<span lang="EN-US">, </span>서비스 개선에 활용</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l7 level1 lfo4;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">⑦<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin"><span style="font-weight: normal;">업계동향 및 이벤트 정보 전달<span lang="EN-US">, </span>마케팅<span lang="EN-US">·</span>광고 등에 활용</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">4.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보의 공유 및 제공</span></b></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.25pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">회사는 이용자의 개인정보를<span lang="EN-US"> "3. </span>개인정보의
이용목적<span lang="EN-US">"</span>에서 고지한 범위내에서 사용하며<span lang="EN-US">, </span>이용자의
사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다<span lang="EN-US">. </span>다만<span lang="EN-US">, </span>아래의 경우에는 예외로 합니다<span lang="EN-US">.</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l1 level1 lfo7;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">이용자가 사전에 동의 한 경우<span lang="EN-US">: </span>서비스내
참여자 정보 표시 등</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l1 level1 lfo7;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">서비스의 제공에 관한 계약의 이행을 위해 필요한 개인정보로서 경제적ㆍ기술적인
사유로 통상의 동의를 받는 것이 현저히 곤란한 경우</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l1 level1 lfo7;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin"><span style="font-weight: normal;">법령의 규정에 의거하거나<span lang="EN-US">, </span>수사
목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">5.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보의 보유 및 이용기간</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다<span lang="EN-US">. </span>단<span lang="EN-US">, </span>다음의 정보에 대해서는 아래의 보존 이유를 위해 명시한 기간
동안 보존합니다<span lang="EN-US">.</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l3 level1 lfo9;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">부정이용기록<span lang="EN-US">(</span>부정가입<span lang="EN-US">, </span>징계기록 등의 비정상적 서비스 이용기록<span lang="EN-US">)</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
2.0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">- </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">보존 항목<span lang="EN-US"> : </span>가입 인증 이메일주소</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
2.0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">- </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">보존 이유<span lang="EN-US"> : </span>부정 가입 및 이용 방지</span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
2.0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">- </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">보존 기간<span lang="EN-US"> : 1</span>년</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l8 level1 lfo5;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">관계법령의 규정에 의하여 보존할 필요가 있는 경우 <span lang="EN-US">‘</span>회사<span lang="EN-US">’</span>는 아래와 같이 일정기간 보유합니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">1) </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">계약 또는
청약철회 등에 관한 기록 <span lang="EN-US">: 5</span>년<span lang="EN-US"> (</span>전자상거래 등에서의 소비자보호에
관한 법률<span lang="EN-US">)</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">2) </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">대금결제
및 재화 등의 공급에 관한 기록 <span lang="EN-US">: 5</span>년<span lang="EN-US"> (</span>전자상거래 등에서의
소비자보호에 관한 법률<span lang="EN-US">)</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">3) </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">전자금융
거래에 관한 기록 <span lang="EN-US">: 5</span>년<span lang="EN-US">(</span>전자금융거래법<span lang="EN-US">)</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">4) </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">소비자의
불만 또는 분쟁처리에 관한 기록 <span lang="EN-US">: 3</span>년<span lang="EN-US">(</span>전자상거래 등에서의
소비자보호에 관한 법률<span lang="EN-US">)</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">5) </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">신용정보의
수집<span lang="EN-US">/</span>처리 및 이용 등에 관한 기록 <span lang="EN-US">: 3</span>년<span lang="EN-US"> (</span>신용정보의 이용 및 보호에 관한 법률<span lang="EN-US">)</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">6) </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">웹사이트
방문기록 <span lang="EN-US">: 3</span>개월<span lang="EN-US">(</span>통신비밀보호법<span lang="EN-US">)</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">6.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보의 파기절차와 방법</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l9 level1 lfo6;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">파기절차 <span lang="EN-US">: </span>이용자의 개인정보는
수집<span lang="EN-US">, </span>이용목적이 달성된 후 별도의<span lang="EN-US"> DB</span>로 옮겨져<span lang="EN-US">(</span>종이의 경우 별도의 서류함<span lang="EN-US">) </span>내부 방침 및 기타 관련 법령에 의한
정보보호 사유에 따라<span lang="EN-US">(</span>보유 및 이용기간 참조<span lang="EN-US">)</span>일정 기간 저장된
후 파기됩니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
2.0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l9 level1 lfo6;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">파기방법<span lang="EN-US"> : </span>전자적 파일형태로
저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다<span lang="EN-US">. </span>종이에 출력된 개인정보는
분쇄기로 분쇄하거나 소각을 통하여 파기합니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">7.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">이용자의 권리와 행사방법</span></b></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:21.25pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></b></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l5 level1 lfo8;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회원은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수
있으며 가입해지를 요청할 수도 있습니다<span lang="EN-US">. </span>귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l5 level1 lfo8;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">개인정보변경<span lang="EN-US">'(</span>또는 <span lang="EN-US">'</span>회원정보수정<span lang="EN-US">' </span>등<span lang="EN-US">)</span>을<span lang="EN-US">, </span>가입해지<span lang="EN-US">(</span>동의철회<span lang="EN-US">)</span>를 위해서는<span lang="EN-US"> "</span>회원탈퇴<span lang="EN-US">"</span>를
클릭하여 본인 확인 절차를 거치신 후 직접 열람<span lang="EN-US">, </span>정정 또는 탈퇴가 가능합니다<span lang="EN-US">. </span>단<span lang="EN-US">, </span>미납요금 등 회원이<span lang="EN-US"> ‘</span>회사<span lang="EN-US">’</span>에 변제할 채무가 남아 있는 경우 유보될 수 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l5 level1 lfo8;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="font-family: 나눔고딕; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">만<span lang="EN-US">14</span>세 미만 아동<span lang="EN-US">(</span>이하
“아동”이라 함<span lang="EN-US">)</span>의 개인정보 수집 시 반드시 법정대리인의 동의를 구하고 있습니다<span lang="EN-US">. </span>회사는 법정대리인의 동의를 받기 위하여 아동으로부터 법정대리인의 성명과 연락 등 최소한의 정보를 요구할 수
있으며<span lang="EN-US">, </span>아동의 법정대리인은 서면<span lang="EN-US">, </span>전화<span lang="EN-US">, E-mail, FAX </span>등을 통하여 아동의 개인정보에 대한 열람<span lang="EN-US">, </span>정정
및 삭제를 요청할 수 있으며<span lang="EN-US">, </span>이러한 요청이 있는 경우 당사는 지체 없이 필요한 조치를 취합니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">8.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보 자동수집 장치의 설치<span lang="EN-US">, </span>운영 및 그 거부에 관한 사항</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">&nbsp;</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는<span lang="EN-US"> ‘</span>쿠키<span lang="EN-US">(cookie)’</span>를 사용합니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l11 level1 lfo10;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는
아주 작은 텍스트 파일로서 이용자 컴퓨터에 저장됩니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l11 level1 lfo10;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">쿠키의 사용목적은 </span><span style="line-height: 115%; letter-spacing: -0.45pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로
불러오는 쿠키를 사용합니다<span lang="EN-US">. </span>이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 디바이스에 저장되어
있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하게 됩니다<span lang="EN-US">. </span>쿠키는 이용자가
웹 사이트를 방문할 때<span lang="EN-US">, </span>웹 사이트 사용을 설정한대로 접속하고 편리하게 사용할 수 있도록 돕습니다<span lang="EN-US">. </span>또한<span lang="EN-US">, </span>이용자의 웹사이트 방문 기록<span lang="EN-US">, </span>이용 형태를 통해서 최적화된 광고 등 맞춤형 정보를 제공하기 위해 활용됩니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l11 level1 lfo10;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="line-height: 115%; letter-spacing: -0.45pt; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;">이용자는 쿠키 수집을 거부할 수 있습니다<span lang="EN-US">. </span>쿠키에는 이름<span lang="EN-US">,
</span>전화번호 등 개인을 식별하는 정보를 저장하지 않으며<span lang="EN-US">, </span>이용자는 쿠키 설치에 대한 선택권을
가지고 있습니다<span lang="EN-US">. </span>이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나<span lang="EN-US">, </span>쿠키가 저장될 때마다 확인을 거치거나<span lang="EN-US">, </span>모든 쿠키의 저장을 거부할
수도 있습니다<span lang="EN-US">. </span>다만<span lang="EN-US">, </span>쿠키 설치를 거부할 경우 웹 사용이
불편해지며<span lang="EN-US">, </span>로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;line-height:115%;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l11 level1 lfo10;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">④<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">쿠키 설정 방법은 아래와 같습니다<span lang="EN-US">.</span></span></span></p>
  <p style="mso-style-name:&quot;작은제목\(중고딕15\)&quot;;
	mso-style-unhide:no;
	margin:0cm;
	margin-bottom:.0001pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:150%;
	mso-pagination:none;
	layout-grid-mode:char;
	text-autospace:none;
	word-break:break-hangul;
	font-size:15.0pt;
	font-family:&quot;한양신명조&quot;,serif;
	mso-fareast-font-family:굴림;
	mso-hansi-font-family:굴림;
	mso-bidi-font-family:굴림;
	color:black;margin-top:14.4pt;margin-right:0cm;margin-bottom:14.4pt;
margin-left:40.0pt;text-indent:-20.0pt;line-height:normal;tab-stops:1240.0pt;
word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="font-size:10.0pt;font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-theme-font:major-latin;mso-hansi-theme-font:
major-latin">1) Internet Explorer</span><span style="font-size:10.0pt;
font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-theme-font:
major-latin;mso-hansi-theme-font:major-latin">의 경우<span lang="EN-US"> : </span>웹 브라우저
상단의 도구 메뉴<span lang="EN-US"> &gt; </span>인터넷 옵션<span lang="EN-US"> &gt; </span>개인정보<span lang="EN-US"> &gt; </span>설정</span></span></p>
  <p style="mso-style-name:&quot;작은제목\(중고딕15\)&quot;;
	mso-style-unhide:no;
	margin:0cm;
	margin-bottom:.0001pt;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:150%;
	mso-pagination:none;
	layout-grid-mode:char;
	text-autospace:none;
	word-break:break-hangul;
	font-size:15.0pt;
	font-family:&quot;한양신명조&quot;,serif;
	mso-fareast-font-family:굴림;
	mso-hansi-font-family:굴림;
	mso-bidi-font-family:굴림;
	color:black;margin-top:14.4pt;margin-right:0cm;margin-bottom:14.4pt;
margin-left:40.0pt;text-indent:-20.0pt;line-height:normal;tab-stops:1240.0pt;
word-break:keep-all"><span lang="EN-US" style="font-size: 10pt; font-family: &quot;맑은 고딕&quot;; font-weight: normal;">2) Chrome</span><span style="font-size:10.0pt;font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-theme-font:major-latin;mso-hansi-theme-font:
major-latin"><span style="font-weight: normal;">의 경우<span lang="EN-US"> : </span>웹 브라우저 우측의 설정 메뉴<span lang="EN-US">
&gt; </span>화면 하단의 고급 설정 표시<span lang="EN-US"> &gt; </span>개인정보의 콘텐츠 설정 버튼<span lang="EN-US"> &gt; </span>쿠키</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin:0cm;margin-bottom:.0001pt;
mso-para-margin-top:0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;
mso-para-margin-left:0gd;mso-para-margin-bottom:.0001pt;text-align:left;
text-indent:0cm;line-height:115%;mso-list:l2 level1 lfo13;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">9.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보의 기술적 대책</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 수집된 개인정보의 안정성 확보를 위하여 아래와 같은 기술적 대책을 강구하고 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l4 level1 lfo11;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">해킹 등에 의한 이용자의 개인정보가 유출되는 것을 방지하기 위해 외부로부터의
침입을 차단하는 네트웍 및 시스템 측면의 별도의 보안 장치<span lang="EN-US">(</span>방화벽<span lang="EN-US">,
IPS </span>및 서버보안등<span lang="EN-US">)</span>를 사용하여 침입의 차단 및 모니터링을 수행하고 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l4 level1 lfo11;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 백신 및 패치 자동 설치 프로그램을 이용하여<span lang="EN-US">, </span>컴퓨터 바이러스에
의한 피해를 방지하기 위한 조치를 취하고 있습니다<span lang="EN-US">. </span>해당 프로그램은 주기적으로 업데이트되며 갑작스러운
바이러스 가 출현할 경우<span lang="EN-US">, </span>즉시 패치를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l4 level1 lfo11;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 암호 알고리즘을 이용하여 네트워크상의 개인정보를 안전하게 전송할 수 있는 보안장치<span lang="EN-US"> (SSL</span>등 암호화 프로토콜<span lang="EN-US">)</span>을 채택하고 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">10.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보의 관리적 대책</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l0 level1 lfo12;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">①<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 이용자의 개인정보의 안정성 확보를 위하여 개인정보 접근권한을 최소한의 인원으로 제한하고 있으며 그에 해당하는
자는 다음과 같습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;text-indent:10.0pt;mso-char-indent-count:1.0;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">- </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">이용자를 직접 상대하여 마케팅 업무를 수행하는 자</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;text-indent:10.0pt;mso-char-indent-count:1.0;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">- </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는 자</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;text-indent:10.0pt;mso-char-indent-count:1.0;line-height:115%;
layout-grid-mode:char;mso-layout-grid-align:none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">- </span><span style="mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">기타 업무상 개인정보의 취급이 불가피한 자</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l0 level1 lfo12;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">②<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">취급자에 의하여 개인정보가 분실<span lang="EN-US">, </span>도난<span lang="EN-US">, </span>유출 등이 되지 아니하도록 의식함양 및 지속적인 교육을 실시합니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l0 level1 lfo12;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">③<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게
이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확화하고 있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l0 level1 lfo12;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">④<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 통제하고
있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l0 level1 lfo12;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">⑤<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다<span lang="EN-US">. </span>회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의<span lang="EN-US"> ID </span>와
비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:20.0pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-20.0pt;
line-height:115%;mso-list:l0 level1 lfo12;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">⑥<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실<span lang="EN-US">, </span>유출<span lang="EN-US">, </span>변조<span lang="EN-US">, </span>훼손이
유발될 경우<span lang="EN-US"> ‘</span>회사<span lang="EN-US">’</span>는 즉각 이용자에게 사실을 알리고 적절한
대책과 보상을 강구할 것입니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">11.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보에 관한 민원서비스</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 개인정보보호책임자를 지정하고
있습니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">[</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">개인정보보호책임자<span lang="EN-US">]</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">성명<span lang="EN-US"> : </span>김영우</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">소속부서<span lang="EN-US"> : </span>대표<span lang="EN-US">(CEO)</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">[</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">고객서비스 담당부서<span lang="EN-US">]</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">부서명 <span lang="EN-US">: </span>서비스기획팀</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">전화번호 <span lang="EN-US">: 02-468-1197 </span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">이메일<span lang="EN-US"> : support@teixon.com</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">이용자는<span lang="EN-US"> ‘</span>회사<span lang="EN-US">’</span>의 서비스를 이용하시면서 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수
있습니다<span lang="EN-US">. ‘</span>회사<span lang="EN-US">’</span>는 이용자들의 신고사항에 대해 신속하게
충분한 답변을 드릴 것입니다<span lang="EN-US">. </span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">12.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">개인정보관련 신고 및 분쟁조정</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">&nbsp;</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다<span lang="EN-US">.</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">개인분쟁조정위원회<span lang="EN-US"> (www.1336.or.kr, 1336)</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">정보보호마크인증위원회<span lang="EN-US"> (www.eprivacy.or.kr, 02-580-0533)</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">대검찰청 인터넷범죄수사센터<span lang="EN-US"> (icic.sppo.go.kr, 02-3480-3600)</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">- </span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">경찰청 사이버테러대응센터<span lang="EN-US"> (www.ctrc.go.kr, 02-392-0330)</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="line-height: 115%; font-weight: normal;">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">13.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">기타</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;
mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">&nbsp;</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="font-weight: normal;"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">‘</span><span style="mso-bidi-font-size:
10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:
major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;
mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">회사<span lang="EN-US">’</span>에 링크되어 있는 타 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본<span lang="EN-US"> ‘</span>회사<span lang="EN-US">’ </span>개인정보처리방침이 적용되지 않음을 알려 드립니다<span lang="EN-US">.</span></span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-priority:34;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:40.0pt;
	mso-para-margin-top:0cm;
	mso-para-margin-right:0cm;
	mso-para-margin-bottom:8.0pt;
	mso-para-margin-left:4.0gd;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:8.5pt;margin-bottom:.0001pt;mso-para-margin-top:
0cm;mso-para-margin-right:0cm;mso-para-margin-bottom:0cm;mso-para-margin-left:
0gd;mso-para-margin-bottom:.0001pt;text-align:left;text-indent:-8.5pt;
line-height:115%;mso-list:l2 level1 lfo1;tab-stops:list 2.85pt;layout-grid-mode:
char;mso-layout-grid-align:none;word-break:keep-all"><b><span lang="EN-US" style="font-size:12.0pt;
mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;
mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:
major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin;
mso-bidi-font-family:&quot;맑은 고딕&quot;;mso-bidi-theme-font:major-latin">14.<span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-weight: normal; font-stretch: normal; font-size: 7pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></b><b><span style="font-size:12.0pt;mso-bidi-font-size:10.0pt;line-height:115%;mso-ascii-font-family:
&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:&quot;맑은 고딕&quot;;
mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;mso-hansi-theme-font:
major-latin">고지의 의무</span></b></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">현 개인정보처리방침 내용 추가<span lang="EN-US">, </span>삭제 및
수정이 있을 시에는 개정 최소<span lang="EN-US"> 7</span>일전부터 이메일을 통해 고지할 것입니다<span lang="EN-US">. </span>다만<span lang="EN-US">, </span>이용자 권리의 중대한 변경이 있을 경우에는 최소<span lang="EN-US"> 30</span>일 전에 고지합니다<span lang="EN-US">.</span></span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span lang="EN-US" style="mso-bidi-font-size:10.0pt;
line-height:115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;
mso-fareast-font-family:&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:
&quot;맑은 고딕&quot;;mso-hansi-theme-font:major-latin">&nbsp;</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="mso-bidi-font-size:10.0pt;line-height:
115%;mso-ascii-font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:major-latin;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:major-latin;mso-hansi-font-family:&quot;맑은 고딕&quot;;
mso-hansi-theme-font:major-latin">부칙</span></p>
  <p align="left" style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;margin-bottom:0cm;margin-bottom:.0001pt;
text-align:left;line-height:115%;layout-grid-mode:char;mso-layout-grid-align:
none;word-break:keep-all"><span style="line-height: 115%; font-weight: normal;">이 개인정보처리방침은 <span lang="EN-US">2023</span>년 <span lang="EN-US">4</span>월 <span lang="EN-US">26</span>일부터 적용합니다<span lang="EN-US">.</span></span></p></h5><p></p>
</div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>