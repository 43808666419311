<template>
<div>
  <p></p><h5 align="center" style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;text-align:center"><span style="font-size:14.0pt;
font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:minor-latin;mso-fareast-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin"><span style="font-size: 12pt;">위치기반 서비스 이용약관</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span lang="EN-US" style="font-family:&quot;맑은 고딕&quot;;mso-ascii-theme-font:minor-latin;
mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:minor-latin">&nbsp;</span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">1</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">목적</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">본 약관은 회원</span><span lang="EN-US" style="font-size: 10pt;">(</span><span style="font-size: 10pt;">서비스 약관에 동의한 자를 말합니다</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">이하</span><span lang="EN-US" style="font-size: 10pt;"> “</span><span style="font-size: 10pt;">회원</span><span lang="EN-US" style="font-size: 10pt;">”</span><span style="font-size: 10pt;">이라고 합니다</span><span lang="EN-US" style="font-size: 10pt;">.)</span><span style="font-size: 10pt;">이
주식회사 테이슨</span><span lang="EN-US" style="font-size: 10pt;"> (</span><span style="font-size: 10pt;">이하</span><span lang="EN-US" style="font-size: 10pt;"> “</span><span style="font-size: 10pt;">회사</span><span lang="EN-US" style="font-size: 10pt;">”</span><span style="font-size: 10pt;">라고 합니다</span><span lang="EN-US" style="font-size: 10pt;">.)</span><span style="font-size: 10pt;">가 제공하는 사용자 위치 기반을 통한 드론</span><span lang="EN-US" style="font-size: 10pt;">&amp;</span><span style="font-size: 10pt;">현장인력 관제 서비스</span><span lang="EN-US" style="font-size: 10pt;">DRONEWORK(</span><span style="font-size: 10pt;">이하</span><span lang="EN-US" style="font-size: 10pt;"> “</span><span style="font-size: 10pt;">서비스</span><span lang="EN-US" style="font-size: 10pt;">”</span><span style="font-size: 10pt;">라고 합니다</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">를
이용함에 있어 회사와 회원의 권리</span><span lang="EN-US" style="font-size: 10pt;">•</span><span style="font-size: 10pt;">의무 및 책임사항을 규정함을 목적으로 합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt; font-weight: normal;">&nbsp;</span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">약관 외 준칙</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">본 약관에 명시되지 않은 사항에 대해서는 위치정보의 보호 및 이용 등에 관한 법률</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">전기통신사업법</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계 법령과 회사가 별도로 정한 서비스의 세부이용지침 등의
규정에 따릅니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt; font-weight: normal;">&nbsp;</span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">3</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">서비스 가입</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">고객은</span><span lang="EN-US" style="font-size: 10pt;"> ‘</span><span style="font-size: 10pt;">테이슨 서비스 이용 약관 제</span><span lang="EN-US" style="font-size: 10pt;"> 6</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">’</span><span style="font-size: 10pt;">에 따라 서비스를 가입 할 수 있으며 회사가 정한 본 약관에 고객이 동의함으로써 서비스 가입의 효력이 발생합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">단 회사는 다음 각 호의 고객 가입신청에 대해서는 이를 승낙하지 아니할 수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.&nbsp;</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">1. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">실명이 아니거나 다른 사람의 명의를 사용하는 등 허위로 신청하는 경우</span><span lang="EN-US"><br><span style="font-size: 10pt;">
2. </span></span><span style="font-size: 10pt;">고객 등록 사항을 누락하거나 오기하여 신청하는 경우</span><span lang="EN-US"><br><span style="font-size: 10pt;">
3. </span></span><span style="font-size: 10pt;">공공질서 또는 미풍양속을 저해하거나 저해할 목적으로 신청한 경우</span><span lang="EN-US"><br><span style="font-size: 10pt;">
4. </span></span><span style="font-size: 10pt;">기타 회사가 정한 이용신청 요건이 충족되지 않을 경우</span><span lang="EN-US"><br><span style="font-size: 10pt;">
5. </span></span><span style="font-size: 10pt;">고객은 그 자격에 따라 서비스 이용의 일부가 제한될 수 있으며 사용기록 및 가입정보</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">진위여부에
따라 고객 자격 및 서비스 이용 가능 범위가 변동될 수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span lang="EN-US" style="font-size: 10pt; font-family: &quot;맑은 고딕&quot;; font-weight: normal;">&nbsp;</span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">4</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">서비스 해지</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">고객은</span><span lang="EN-US" style="font-size: 10pt;"> ‘</span><span style="font-size: 10pt;">테이슨 서비스 이용 약관 제</span><span lang="EN-US" style="font-size: 10pt;"> 8</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">’</span><span style="font-size: 10pt;">에 따라 서비스를 해지</span><span lang="EN-US" style="font-size: 10pt;">(</span><span style="font-size: 10pt;">탈퇴</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">할
수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5>
  <h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt; font-weight: normal;">&nbsp;</span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">5</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">개인위치정보 이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공에
관한 동의</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">조건</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">① 회사는 위치기반서비스를 제공하기 위하여 고객의 개인위치정보를 이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공하며</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">고객은 서비스 가입 및 기타 방법으로 개인위치정보주체 본인을 인증하여 동의를 획득합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;"> 8</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">세 이하 아동의 경우</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">아동</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">치매</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">정신질환</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">중증
정신지체 등으로 사리를 분별할 능력이 없는 자</span><span lang="EN-US" style="font-size: 10pt;"> (</span><span style="font-size: 10pt;">이하</span><span lang="EN-US" style="font-size: 10pt;"> '</span><span style="font-size: 10pt;">의사무능력자등</span><span lang="EN-US" style="font-size: 10pt;">'</span><span style="font-size: 10pt;">이라 한다</span><span lang="EN-US" style="font-size: 10pt;">.)</span><span style="font-size: 10pt;">의 친권자</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">후견인</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">부양의무자 그 밖에 의사무능력자등을 사실상 보호하는 자로서 대통령령이 정하는 자</span><span lang="EN-US" style="font-size: 10pt;">(</span><span style="font-size: 10pt;">이하</span><span lang="EN-US" style="font-size: 10pt;"> '</span><span style="font-size: 10pt;">친권자등</span><span lang="EN-US" style="font-size: 10pt;">'</span><span style="font-size: 10pt;">이라 한다</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">가 의사무능력자등의 생명 또는 신체의 보호를 위하여 의사무능력자 등의 개인위치정보의 이용 및 제공에 동의하는
경우</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">친권자등임을 증명하는 증빙서류를 제출하는 건에 한하여 동의획득으로 인정합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">③</span><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;"> 8</span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 수집</span><span style="font-family: &quot;MS Gothic&quot;; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">이용 또는 제공에 동의를 하고자 하는 자는 서면동의서에</span><span lang="EN-US" style="font-size: 10pt;"> 8</span><span style="font-size: 10pt;">세 이하의 아동 등의 보호의무자임을
증명하는 서면을 첨부하여 회사에 제출하여야 하며 서면동의서에는 다음 사항을 기재하고 그 보호의무자가 기명날인 또는 서명하여야 합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">1. 8</span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">세 이하의 아동 등의 성명</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">주소 및 생년월일</span><span lang="EN-US"><br><span style="font-size: 10pt;">
2. </span></span><span style="font-size: 10pt;">보호의무자의 성명</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">주소 및 연락처</span><span lang="EN-US"><br><span style="font-size: 10pt;">
3. </span></span><span style="font-size: 10pt;">개인위치정보 수집</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">이용 또는 제공의 목적이</span><span lang="EN-US" style="font-size: 10pt;"> 8</span><span style="font-size: 10pt;">세
이하의 아동 등의 생명 또는 신체의 보호에 한정된다는 사실</span><span lang="EN-US"><br><span style="font-size: 10pt;">
4. </span></span><span style="font-size: 10pt;">동의의 연월일</span></span></span></h5>
  <br/>
  <h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">6</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">개인위치정보 이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공에
관한 동의의 철회</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">고객은 서비스 해지 등의 방법을 통하여 개인위치정보의 이용 또는 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5>  <br/>
  <h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">7</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">서비스 내용 및 이용정보</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">① 회사는 직접 위치정보를 수집하거나 위치정보사업자로부터 위치정보를 전달받아 아래와 같은 위치기반서비스를 제공합니다</span><span lang="EN-US" style="font-size: 10pt;">.&nbsp;</span></span></h5><br/><h5 align="center" style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;text-align:center"><p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US" style="line-height: 107%; font-size: 10pt;">1.
</span><span style="mso-bidi-font-size:10.0pt;line-height:107%;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:minor-latin"><span style="font-size: 10pt;">드론의 비행 위치 정보</span></span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US" style="line-height: 107%; font-size: 10pt;">2.
</span><span style="mso-bidi-font-size:10.0pt;line-height:107%;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:minor-latin"><span style="font-size: 10pt;">드론 조종자의 위치 정보</span></span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US" style="line-height: 107%; font-size: 10pt;">3.
</span><span style="mso-bidi-font-size:10.0pt;line-height:107%;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:minor-latin"><span style="font-size: 10pt;">현장 작업자의 위치 정보</span></span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal;"><span lang="EN-US" style="line-height: 107%; font-size: 10pt;">4.
</span><span style="mso-bidi-font-size:10.0pt;line-height:107%;mso-fareast-font-family:
&quot;맑은 고딕&quot;;mso-fareast-theme-font:minor-latin"><span style="font-size: 10pt;">회원의 매니저 웹 접속 정보</span></span></span></p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;text-indent:10.0pt;mso-char-indent-count:1.0"><span lang="EN-US" style="line-height: 107%; font-size: 10pt; font-weight: normal;"></span></p></h5>
  <h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt; font-weight: normal;">&nbsp;</span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">8</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">개인 위치정보의 제공</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">회사는 고객의 개인위치정보를 고객이 지정하는 제</span><span lang="EN-US" style="font-size: 10pt;">3</span><span style="font-size: 10pt;">자에게 제공하는 경우에는 개인위치정보를
수집한 당해 통신 단말장치로 매회 고객에게 제공받는 자</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">제공일시 및 제공목적을 즉시 통보합니다</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">단</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">다음에 해당하는 경우에는 고객이 미리 특정하여 지정한 연락처
및 이메일 주소로 통보합니다</span><span lang="EN-US" style="font-size: 10pt;">.&nbsp;</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">1. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">개인위치정보를 수집한 당해 통신단말장치가 문자</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">음성 또는 영상의
수신기능을 갖추지 아니한 경우</span><span lang="EN-US"><br><span style="font-size: 10pt;">
2. </span></span><span style="font-size: 10pt;">회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">9</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">고객의 개인위치정보 보호</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">회사는 관련법령이 정하는 바에 따라서 고객의 개인위치정보를 보호하기 위하여 노력합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span lang="EN-US" style="font-size: 10pt; font-family: &quot;맑은 고딕&quot;; font-weight: normal;">&nbsp;</span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">10</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">위치정보 이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공사실
확인자료의 보유</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">①</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사는 위치정보의 보호 및 이용 등에 관한 법률 제</span><span lang="EN-US" style="font-size: 10pt;">16</span><span style="font-size: 10pt;">조
제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">항에 근거하여 고객에 대한 위치정보의 수집</span></span><span style="font-family: &quot;MS Gothic&quot;; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">이용</span><span style="font-family: &quot;MS Gothic&quot;; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제공 사실 확인자료를 위치정보시스템에 자동으로 기록하며</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">관련 자료는 위치정보서비스 제공을
위해 고객의 서비스 가입 시점부터 서비스 해지 시점까지 보유합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사는 위치정보의 보호 및 이용 등에 관한 법률 제</span><span lang="EN-US" style="font-size: 10pt;">24</span><span style="font-size: 10pt;">조
제</span><span lang="EN-US" style="font-size: 10pt;">4</span><span style="font-size: 10pt;">항의 규정에 의하여 회사는 고객이 동의의 전부 또는 일부를 철회한 경우에는 지체 없이 수집된 개인위치정보
및 위치정보의 수집</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공사실 확인자료</span><span lang="EN-US" style="font-size: 10pt;">(</span><span style="font-size: 10pt;">동의의 일부를 철회하는 경우에는 철회하는 부분의 개인위치정보 및 위치정보 이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공사실
확인자료에 한합니다</span><span lang="EN-US" style="font-size: 10pt;">) </span><span style="font-size: 10pt;">를 지체없이 파기합니다</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">다만</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">고객이 별도로 동의하거나 고객의 불만</span><span lang="EN-US" style="font-size: 10pt;">/</span><span style="font-size: 10pt;">분쟁 처리 또는 국세기본법</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">법인세법</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">부가가치세법 기타 관계법령의 규정에 의하여 보존할 필요성이
있는 경우에는 관계법령에 따라 보존합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">11</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">개인위치정보의 보유목적 및 이용기간</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">회사는 위치정보 서비스를 이행하기 위한 목적으로 보유하는 고객의 개인위치정보의 이용 또는 제공 목적을 달성하거나</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">개인위치정보의 이용 또는 제공 목적을 달성하거나</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">고객이 개인위치정보의
이용에 대한 동의의 전부 또는 일부에 대하여 철회한 때에는 당해 개인위치정보를 지체 없이 파기합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">12</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">법정대리인의 권리</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">①</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사는</span><span lang="EN-US" style="font-size: 10pt;"> 14</span><span style="font-size: 10pt;">세 미만의 아동으로부터 위치정보의
보호 및 이용 등에 관한 법률 제</span><span lang="EN-US" style="font-size: 10pt;">18</span><span style="font-size: 10pt;">조 제</span><span lang="EN-US" style="font-size: 10pt;">1</span><span style="font-size: 10pt;">항</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">19</span><span style="font-size: 10pt;">조 제</span><span lang="EN-US" style="font-size: 10pt;">1</span><span style="font-size: 10pt;">항</span></span><span style="font-family: &quot;MS Gothic&quot;; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">항 또는 제</span><span lang="EN-US" style="font-size: 10pt;">21</span><span style="font-size: 10pt;">조의 규정에 의하여 개인위치정보를
수집</span></span><span style="font-family: &quot;MS Gothic&quot;; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">이용 또는 제공하고자 하는 경우에는 아동 본인 및 그 법정대리인의 동의를 얻어야 합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 본 약관 제</span><span lang="EN-US" style="font-size: 10pt;">14</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">(</span><span style="font-size: 10pt;">고객의 권리</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">의 규정은 위치정보의 보호 및 이용 등에 관한 법률 제</span><span lang="EN-US" style="font-size: 10pt;">25</span><span style="font-size: 10pt;">조 제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">항의 규정에 의하여 법정대리인이 동의를 하는 경우에 이를
준용합니다</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">이 경우</span><span lang="EN-US" style="font-size: 10pt;">, '</span><span style="font-size: 10pt;">고객</span><span lang="EN-US" style="font-size: 10pt;">'</span><span style="font-size: 10pt;">은</span><span lang="EN-US" style="font-size: 10pt;"> '</span><span style="font-size: 10pt;">법정대리인</span><span lang="EN-US" style="font-size: 10pt;">'</span><span style="font-size: 10pt;">으로 봅니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">13</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회사의 의무</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">①</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사는 고객의 개인위치정보 이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공과 관련한
고객의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">신속한 처리가 곤란한 경우 그 사유와
처리 일정을 고객에게 통지합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사는 위치정보의보호및이용등에관한법률</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">정보통신망이용촉진및보호등에관한법률
등 고객의 개인위치정보 이용</span><span lang="EN-US" style="font-size: 10pt;">·</span><span style="font-size: 10pt;">제공과 관련이 있는 법규를 준수합니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">14</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">고객의 권리</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">① 고객은 회사의 고객 개인위치정보의 수집에 대한 동의의 전부 또는 일부에 대하여 철회할 수 있습니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객은 회사의 고객 개인위치정보 수집의 일시적인 중지를 요구할 수 있습니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">③</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객은 제</span><span lang="EN-US" style="font-size: 10pt;">1</span><span style="font-size: 10pt;">항 및 제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">항에 의한 개인위치정보 수집에 대한 동의철회 및 중지를 전화</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">우편</span><span lang="EN-US" style="font-size: 10pt;">, APP</span><span style="font-size: 10pt;">를 통해 요청할 수 있습니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">④</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객이 제</span><span lang="EN-US" style="font-size: 10pt;">1</span><span style="font-size: 10pt;">항 또는 제</span><span lang="EN-US" style="font-size: 10pt;">2</span><span style="font-size: 10pt;">항에 따라 개인위치정보 수집에 대한 동의를 철회 또는 중지하는 경우 고객에 대한 회사의 각종 서비스의 제공이
불가능하게 될 수 있습니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">⑤</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객은 회사를 방문하여 다음과 같은 자료 등의 열람 또는 고지를 요구할 수 있고 당해
자료 등에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.&nbsp;</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">1. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">고객에 대한 위치정보 수집</span><span style="font-family: &quot;MS Gothic&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">이용</span><span style="font-family: &quot;MS Gothic&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">제공 사실 확인자료</span><span lang="EN-US"><br><span style="font-size: 10pt;">
2. </span></span><span style="font-size: 10pt;">고객의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률의 규정에 의하여 제</span><span lang="EN-US" style="font-size: 10pt;">3</span><span style="font-size: 10pt;">자에게 제공된 이유 및 내용</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">15</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">고객의 의무</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">① </span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">고객은 서비스의 제공을 위하여 회사가 개인위치정보를 수집하는데 필요한 제반 정보를 제공</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">등록할
경우 현재의 사실과 일치하는 완전한 정보를 제공</span></span><span style="font-family: &quot;MS Gothic&quot;; font-size: 10pt;">‧</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">등록하여야 하며</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">변경사항이 발생할 경우 즉시 갱신하여야 합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객은 회사가 위치정보를 수집하여 제공하는 서비스를 원활하게 이용하기 위하여 위치 등
위치정보수집 단말장치</span><span lang="EN-US" style="font-size: 10pt;">(</span><span style="font-size: 10pt;">이하</span><span lang="EN-US" style="font-size: 10pt;"> '</span><span style="font-size: 10pt;">대상 단말기</span><span lang="EN-US" style="font-size: 10pt;">'</span><span style="font-size: 10pt;">라 합니다</span><span lang="EN-US" style="font-size: 10pt;">)</span><span style="font-size: 10pt;">가 정상 동작을 유지하도록 관리하여야 하며</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">대상 단말기의 정상 동작이 유지되지 않아 회사의 개인위치정보 수집에 지장이 발생한 경우에는 해당 대상 단말기의
보수</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">교환 등의 처리를 하여 회사의 서비스 제공을 위한 위치정보 수집이 정상적으로 이루어지도록 합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">③</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객은 다음 각 호의 어느 하나에 해당하는 행위를 하여서는 안됩니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><span style="border: 1pt none windowtext; padding: 0cm; font-size: 10pt;"> </span></span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">1. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">개인위치정보 수집과 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를
등록 또는 유포하는 행위</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">2. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">3. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">자기 또는 타인에게 재산상의 이익을 주거나 손해를 가할 목적으로 허위의 정보를 유통시키는 행위</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">4. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">타인의 개인위치정보를 무단으로 유용 또는 유출하는 행위</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">5. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">기타 불법적이거나 부당한 행위</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">&nbsp;</span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">④</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객은 관계 법령</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">이 약관의 규정</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">이용안내 및 서비스 상에 공지한 주의사항</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">회사가 통지하는 사항 등을 준수하여야 하며</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">기타 회사의 업무에
방해되는 행위를 하여서는 안됩니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">16</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">회사의 연락처</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 10.5pt; font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">회사의 상호</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">주소</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">전화
번호 그 밖의 연락처는 다음과 같습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm; font-size: 10pt;">1. </span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">상호</span><span lang="EN-US" style="font-size: 10pt;">: </span><span style="font-size: 10pt;">주식회사 테이슨</span><span lang="EN-US"><br><span style="font-size: 10pt;">
2. </span></span><span style="font-size: 10pt;">주소</span><span lang="EN-US" style="font-size: 10pt;">: </span><span style="font-size: 10pt;">서울특별시 성동구 성수이로</span><span lang="EN-US" style="font-size: 10pt;">10</span><span style="font-size: 10pt;">길
</span><span lang="EN-US" style="font-size: 10pt;">14, 402</span><span style="font-size: 10pt;">호</span><span lang="EN-US"><br><span style="font-size: 10pt;">
3. </span></span><span style="font-size: 10pt;">대표전화</span><span lang="EN-US" style="font-size: 10pt;">: 02-468-1197</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">17</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">위치정보 관리책임자</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-family: &quot;맑은 고딕&quot;; font-weight: normal;"><span style="font-size: 10pt;">회사의 위치정보관리책임자는 다음과 같습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;margin-left:40.0pt;text-indent:-18.0pt;mso-list:l0 level1 lfo1"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">1.</span><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 10pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">직위</span><span lang="EN-US" style="font-size: 10pt;">: </span><span style="font-size: 10pt;">서비스 운영 책임</span></span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;margin-left:40.0pt;text-indent:-18.0pt;mso-list:l0 level1 lfo1"><span style="font-weight: normal;"><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">2.</span><span style="font-variant-numeric: normal; font-variant-east-asian: normal; font-variant-alternates: normal; font-kerning: auto; font-optical-sizing: auto; font-feature-settings: normal; font-variation-settings: normal; font-stretch: normal; font-size: 10pt; line-height: normal; font-family: &quot;Times New Roman&quot;;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style="font-family: &quot;맑은 고딕&quot;; border: 1pt none windowtext; padding: 0cm;"><span style="font-size: 10pt;">번호</span><span lang="EN-US" style="font-size: 10pt;">: 02-468-1197</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">18</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">손해배상</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">①</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객 또는 위치기반서비스사업자가 고의나 과실에 의해 이 약관의 규정을 위반함으로 인하여
회사에 손해가 발생하게 되는 경우</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">이 약관을 위반한 당사자는 회사에 발생한 손해를 배상하여야 합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 고객의 불법 행위나 약관 위반행위로 인하여 회사가 제</span><span lang="EN-US" style="font-size: 10pt;">3</span><span style="font-size: 10pt;">자로부터
손해배상청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 불법행위 또는 약관 위반행위를 한 자는 자신의 책임과 비용으로 회사를 면책시켜야
하며</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">회사가 면책되지 못한 경우 그로 인하여 회사에 발생한 손해를 배상하여야 합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">③</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사가 위치정보의보호및이용등에관한법률 제</span><span lang="EN-US" style="font-size: 10pt;">15</span><span style="font-size: 10pt;">조
내지 제</span><span lang="EN-US" style="font-size: 10pt;">26</span><span style="font-size: 10pt;">조의 규정을 위반하여 고객에게 손해가 발생한 경우 회사가 고의 또는 과실이 없음을 입증하지
아니하면 고객의 손해에 대해 책임을 집니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">19</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">면책사항</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">①</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 개인위치정보를 수집할 수 없는
경우 이에 관한 책임이 면제됩니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 회사는 고객의 귀책사유로 인하여 회사가 개인위치정보를 수집하지 못하거나 잘못 수집하여
발생하는 서비스의 이용 장애에 대하여 책임을 지지 않습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><br/><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-size: 11pt; font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;">제</span><span lang="EN-US" style="font-size: 10pt;">20</span><span style="font-size: 10pt;">조</span><span lang="EN-US" style="font-size: 10pt;">. </span><span style="font-size: 10pt;">분쟁의 해결</span></span></h5><h5 style="mso-style-priority:9;
	mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-link:&quot;제목 5 Char&quot;;
	mso-margin-top-alt:auto;
	margin-right:0cm;
	mso-margin-bottom-alt:auto;
	margin-left:0cm;
	mso-pagination:widow-orphan;
	mso-outline-level:5;
	font-size:10.0pt;
	font-family:굴림;
	mso-bidi-font-family:굴림;"><span style="font-weight: normal;"><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">①</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 개인위치정보의 수집과 관련하여 회사와 고객 사이에 분쟁이 발생한 경우</span><span lang="EN-US" style="font-size: 10pt;">, </span><span style="font-size: 10pt;">회사와 고객은 분쟁의 해결을 위해 성실히 협의합니다</span><span lang="EN-US"><span style="font-size: 10pt;">.</span><br><span style="font-size: 10pt;">
</span></span></span><span style="font-family: &quot;맑은 고딕&quot;; font-size: 10pt;">②</span><span style="font-family: &quot;맑은 고딕&quot;;"><span style="font-size: 10pt;"> 제</span><span lang="EN-US" style="font-size: 10pt;">1</span><span style="font-size: 10pt;">항의 협의에도 불구하고 회사와 고객간
분쟁이 해결되지 않을 경우 양 당사자는 개인정보보호법 제</span><span lang="EN-US" style="font-size: 10pt;">43</span><span style="font-size: 10pt;">조의 규정에 의한 개인정보분쟁조정위원회에
조정을 신청할 수 있습니다</span><span lang="EN-US" style="font-size: 10pt;">.</span></span></span></h5><br/><br/><h5 style="margin-right: 0cm; font-size: 10pt; font-family: 굴림;"><p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;">부칙</p>
  <p style="mso-style-unhide:no;
	mso-style-qformat:yes;
	mso-style-parent:&quot;&quot;;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	mso-pagination:none;
	text-autospace:none;
	word-break:break-hangul;
	font-size:10.0pt;
	mso-bidi-font-size:11.0pt;
	font-family:&quot;맑은 고딕&quot;;
	mso-ascii-font-family:&quot;맑은 고딕&quot;;
	mso-ascii-theme-font:minor-latin;
	mso-fareast-font-family:&quot;맑은 고딕&quot;;
	mso-fareast-theme-font:minor-fareast;
	mso-hansi-font-family:&quot;맑은 고딕&quot;;
	mso-hansi-theme-font:minor-latin;
	mso-bidi-font-family:&quot;Times New Roman&quot;;
	mso-bidi-theme-font:minor-bidi;
	mso-font-kerning:1.0pt;"><span style="font-weight: normal; margin-top: 30px;"><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">부칙</span><span lang="EN-US"><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;"> (2023. 04. 26)</span><br><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">
(</span></span><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">시행일</span><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">) </span><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">이 약관은</span><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;"> 2023</span><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">년 </span><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">04</span><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">월</span><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;"> 26</span><span style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">일부터 시행한다</span><span lang="EN-US" style="font-family: &quot;맑은 고딕&quot;, &quot;Malgun Gothic&quot;, Sans-serif; font-size: 10pt;">.</span></span></p></h5><p></p>
</div>
</template>

<script>
export default {
  name: "LocationBasedServices"
}
</script>

<style scoped>

</style>